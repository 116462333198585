import Library from 'library';
import { Actions } from 'reduxs';
import Service from 'gktapp-service/dist/src/h5/service';
import Common from './h5/index';

const { Fetch, Platform, JsBridge } = Library;
const sourceMap = {
    'Wechat': 'wh5',
    'Wap': 'h5',
    'GuApp': 'App'
};
const SMSdk = window.SMSdk;

function dealSmDeviceId() {
    return new Promise((resolve, reject) => {
        const smTimeoutTime = 1000;
        let smDeviceId = '';
        let smDeviceIdReady = false;

        function getSmDeviceCallBack() {
            smDeviceId = SMSdk.getDeviceId ? SMSdk.getDeviceId() : smDeviceId;
            clearTimeout(smTimer);
            if (!smDeviceIdReady) {
                smDeviceIdReady = true;
                //执行业务逻辑
                resolve((smDeviceId.length <= 100 && smDeviceId) || '');
            }
            return { smDeviceId, smDeviceIdReady };
        }

        const smTimer = setTimeout(getSmDeviceCallBack, smTimeoutTime);
        SMSdk.ready(getSmDeviceCallBack);
    });
}
const appService = async (module, serviceName, params, customLoading = false) => {
    const result = await JsBridge.runServices(module, serviceName, params ? [params] : [], !customLoading);
    if (result.status > 0) {
        return result;
    } else {
        return false;
    }

}

const h5Service = async (moduleName, service, params, customLoading = false) => {

    const _Service = Service;
    const _Common = Common;
    const Services = {..._Service, ..._Common};

    const module = Services[moduleName];
    console.log(module);
    if (!module) {
        throw `module【${moduleName}】 not exist`;
    }

    let ServiceClass;
    if(typeof service === 'string') {
        ServiceClass = module[service];
        console.log(ServiceClass);
    }else {
        ServiceClass = module['BaseService'];
    }

    if (!ServiceClass) {
        throw `request【${moduleName}/${service}】 not exist`;
    }

    const serviceClass = new ServiceClass()
    if(typeof service == 'object') {
        serviceClass.code = service.code
        serviceClass.method = service.method || 'post';
    }
    const smDeviceId = await dealSmDeviceId();
    if(params && Object.prototype.toString.call(params.headers) === '[object Object]') {
        Fetch.headers = params.headers;
        delete params.headers;
    }
    const fetch = serviceClass.createFetch({ url: '/h5/api/', source: Platform.check.isWechat() ? 'wh5' : 'h5', version: 'wh5', smDeviceId: smDeviceId }, Fetch);

    fetch.requestParams = params || {};
    if (!customLoading && moduleName != 'Common') {
        Actions.loading.start();
    }
    const res = await fetch.fetch('h5');
    if (!customLoading) {
        Actions.loading.end();
    }
    if (res.status > 0) {
        if (res.data.success !== false && (!res.data.code || res.data.code === '000000')) {
            return res;
        } else {
            if (res.data.errcode === 'SESSION_EXPIRED') {
                if (Platform.check.isWechat()) {
                    if (window.location.pathname.indexOf('/front/custom') > -1) {
                        window.location.href = '/jkt/coupon/wxentrance?state=' + encodeURIComponent(window.location.pathname + window.location.search);
                    } else if (window.location.pathname.indexOf('/front/admin') > -1) {
                        window.location.href = '/jkt/coupon/verification/wxentrance?state=' + encodeURIComponent(window.location.pathname + window.location.search);
                    } else {
                        window.location.href = '/jkt/coupon/wxentrance?state=' + encodeURIComponent(window.location.pathname + window.location.search);
                    }
                    // if (window.location.pathname.indexOf('/front/admin') > -1 || window.location.pathname.indexOf('/front/admin') > -1) {
                    //     window.location.href = '/ldgu/jkt/coupon/wxentrance?state=' + encodeURIComponent('/front/admin/index/login');
                    // } else {
                    //     window.location.href = '/ldgu/guMember/wxentrance?state=' + encodeURIComponent(window.location.pathname);
                    // }
                }
            } else if (res.data.msg) {
                Actions.alert.show(res.data.msg);
            }
            throw (res.data.msg);
        }
    } else if (res.status === -1000) {
        return false;
    } else {
        Actions.alert.show(res.msg || '网络请求异常!');
        return false;
    }
}

export default async (module, service, params, customLoading = false) => {
    if (Platform.check.isApp()) {
        return await appService(module, service, params, customLoading);
    } else {
        console.log(module, service);
        return await h5Service(module, service, params, customLoading).catch(e => {
            return { status: -1, error: e };
        });
    }
};
