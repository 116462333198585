/**
 * @description 地址管理列表
 * route: /front/guapp/address/addressList
 * 选择地址时需携带参数 /front/guapp/address/addressList?mode=1
 * 选择地址后，地址通过JsBridge的callback回调,获取方法:
    const res = await window.JsBridge.push('CustomWeb',{
        uri: window.location.origin + `/front/guapp/address/addressList?mode=1`, 
        callback: true
    })
    const data = res.result
    if(data){
        window.JsBridge.print(data)
    }
*/
import React, {useEffect, useState, Fragment} from "react";
import {SwipeAction, Toast} from "antd-mobile";
import { useHistory } from "react-router-dom";
import Library,{ Fetch, Platform, JsBridge } from 'library';
import { Header, ListView, ModalAlert } from 'components';
import Service from "../service";
import './index.scss';

//本地缓存key（也将用于其他项目地址缓存）
const kLocalSelectAddressKey = Service.kLocalSelectAddressKey;

const AddressList = (props) => {
    // 获取参数
    const { mode } = Library.Util.url.paramsToObj();

    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const currentPage = React.useRef(1)

    useEffect(()=>{
        loadData()
    },[])

    const goBackListener = ()=> {
        window.JsBridge.addListener().then(() => {
            loadData(false)
        });
    }

    //加载列表数据
    const loadData = async (loading=true) => {
        if(loading) {
            setLoading(true)
        }
        const reslut = await Service.queryAddressList({},loading)
        window.JsBridge.print(reslut)
        if(reslut) {
            setList(reslut.data || [])
        }
        if(loading) {
            setLoading(false)
        }
        
    }

    //新增地址事件
    const addAddress = ()=> {
        console.log('addAddress')
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/guapp/address/addressEdit`});
        }else {
            props.history.push('/front/guapp/address/addressEdit')
        }
        goBackListener()
    }

    const emtyView = (
        <div className="address-emty">
            <img src={require('images/none-address.png')} alt="" />
            {
                loading ?
                <div className="title">{'数据加载中...'}</div>:
                <Fragment>
                    <div className="title">{'没有收货地址'}</div>
                    <span className="subTitle">{'“快添加收货地址，买买买吧” '}</span>
                    <div className="address_add_btn" onClick={addAddress}>
                        <span>{'添加收货地址'}</span>
                    </div>
                </Fragment>
            }
            
        </div>

    )

    const renderItem = (item) => {
        return (
            <ListItem item={item} mode={mode} 
                goBackListener={goBackListener}
                onRefresh={(loading)=>{
                    loadData(loading)
                }}
            />
        )
    }

    return (
        <div className="address_list_page">
            <Header midText={`收货地址`} bgColor={'#fff'}/>
            {
                list.length > 0 ?
                <div className="page_body">
                    <ListView 
                        dataSource={list}
                        renderItem={renderItem}
                        emtyImg={null}
                        emtyDesc={emtyView}
                        hasMore={false}
                        onRefresh={()=>{
                            console.log('onRefresh')
                            currentPage.current = 1
                            loadData(false)
                        }}
                        loadMore={()=>{
                            console.log('loadMore')
                        }}
                    />
                    {
                        //最多20条地址数据
                        list.length < 20 ?
                        <div className="bottom-view">
                            <div className="bottom-btn" onClick={addAddress}>
                                + 新增收货地址
                            </div>
                        </div>
                        :null
                    }
                </div>
                :emtyView
            }
            
        </div>
    )
}

const ListItem = (props) => {
    const {item={}, mode} = props
    const history = useHistory()
    
    //跳转编辑事件
    const onEditClick = (e)=> {
        e && e.stopPropagation()
        if (window.JsBridge.hasWebViewBridge()) {
            window.JsBridge.push('CustomWeb',{uri:`${window.location.origin}/front/guapp/address/addressEdit?addressInfo=${JSON.stringify(item)}`});
        }else {
            history.push(`/front/guapp/address/addressEdit?addressInfo=${JSON.stringify(item)}`)
        }
        props.goBackListener && props.goBackListener()
    }

    // 点击事件
    const onItemClick = ()=> {
        console.log('onItemClick')
        if(mode == 1) {
            if(item.needComplete) {
                //是否需要完善地址
                ModalAlert.show({
                    title: <span style={{fontFamily:'initial'}}>{'需完善收货地址'}</span>,
                    content: '',
                    onAction: async (it, index) => {
                        if(it.key === 'confirm'){
                            onEditClick()
                        }
                        ModalAlert.hide()
                    },
                    actions: [
                        {
                            key: 'cancel',
                            text: '取消'
                        },
                        {
                            key: 'confirm',
                            text: '去完善',
                        }
                    ]
                })
            }else {
                if (window.JsBridge.hasWebViewBridge()) {

                    window.JsBridge.callback(item);
                    window.JsBridge.back()
                }else {
                    sessionStorage.setItem(kLocalSelectAddressKey, JSON.stringify(item));
                    history.go(-1)
                }
            }
            
        }else{
            onEditClick()
        }
    }

    //侧滑事件
    const onAction = async (action)=> {
        console.log(action)
        if(action.key === 'default'){
            const res = await Service.defaltAddressSetting(item)
            if(res){
                Toast.show("设置成功")
                props.onRefresh && props.onRefresh(false)
            }
            
        }else if(action.key === 'delete'){
            ModalAlert.show({
                title: <span style={{fontFamily:'initial'}}>{'确定要删除该地址吗？'}</span>,
                content: '',
                onAction: async (it, index) => {
                    if(it.key === 'confirm'){
                        const res = await Service.deleteAddress(item)
                        if(res){
                            props.onRefresh && props.onRefresh()
                        }
                    }
                    ModalAlert.hide()
                },
                actions: [
                    {
                        key: 'cancel',
                        text: '取消'
                    },
                    {
                        key: 'confirm',
                        text: '确认删除',
                    }
                ]
            })
        }
    }

    const rightActions = [
        {
          key: 'default',
          text: <span style={{fontSize:13, color: '#333333'}}>{'设为默认'}</span>,
          color: '#E7E7E7',
        },
        {
          key: 'delete',
          text: <span style={{fontSize:13, color: '#DFC197', padding: '0 10px'}}>{'删除'}</span>,
          color: '#464646',
        },
      ]

    return (
        <div className="address-list-item">
            <SwipeAction rightActions={rightActions}
                onAction={onAction}
            >
                <div className="item-body" onClick={onItemClick}>
                    <img src={require('images/address_icon.png')} alt="" />
                    <div className="body-center">
                        <div className="user_info">
                            <span>{item.consigneeName}</span>
                            <span>{item.consigneeNumber}</span>
                           {item.isDefaltAddress == 1 && <span>{'默认'}</span>} 
                        </div>
                        <div className="address-info">
                            {(item.provinceName || '') + (item.cityName || '') + (item.areaName || '') + (item.streetName || '') + (item.deliveryAddress || '')}
                        </div>
                    </div>
                    <div className="body-right" onClick={onEditClick}>
                        <img src={require('images/address_edit_icon.png')} alt="" />
                    </div>
                </div>
                {
                    item.needComplete &&
                    <div className="item-footer">
                        <span>{'需完善收货地址'}</span>
                    </div>
                }
                
            </SwipeAction>
        </div>
    )
}

export default AddressList;