import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Store, History } from 'reduxs';
// import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';
import { RouteRender } from './routes';
import './scss/index.scss';
import Library from "library"
const { Util } = Library


function App() {
  const { isUnity='0' } = Util.url.paramsToObj()
  const [pageStatus, setPageStatus] = useState(false)

  useEffect(() => {
    if (isUnity === '1') {
      window.addEventListener('message', async (event) => {
        if (typeof event.data == 'string' && event.data == 'UnityFinshed') {
          if (event.data === 'UnityFinshed') {
            if (window.Unity) {
              console.log('window.Unity.message---', window.Unity.message)
              setPageStatus(true)
            }
          }
        }
      }, false);
    }
  }, [])

  if (!pageStatus && isUnity === '1') return null

  return <Provider store={Store}>
    <Router history={History}>
      <RouteRender />
    </Router>
  </Provider>;
}

export default App;
